import React, { useState } from 'react';
import { FiLogIn, FiLock, FiUser } from 'react-icons/fi';

import * as Yup from 'yup';
import { useFormik, FormikProvider, Form } from 'formik';

import {
  Container,
  Content,
  AnimationContainer,
} from './styles';

import getValidationErrors from '../../utils/getValidadeErros';

import { UseToast } from '../../context/Toast';
import { UseAuth } from '../../context/Auth';
import { UseLanguage } from '../../context/Lang';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import { Link } from 'react-router-dom';

import logoImg from '../../assets/logostudio.jpeg';

const Login: React.FC = () => {
  const { signIn } = UseAuth();
  const { addToast } = UseToast();
  const [loading, setLoading] = useState(false);
  const { t } = UseLanguage();
  const { language } = navigator;

  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required('Login obrigatório.'),
      password: Yup.string().required('Senha obrigatória'),
    }),
    onSubmit(values, {resetForm}) {
      setLoading(true);
      try {
        formik.setErrors({});
        signIn({
          username: values.username,
          password: values.password,
          lang: language.toLowerCase(),
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formik.setErrors(errors);
          setTimeout(() => {
            setLoading(false);
            resetForm();
          }, 1000 * 2);
        }
        console.log(err);
        addToast({
          type: 'error',
          title: 'Error',
          description: t('authentication.error.description'),
        });
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000 * 1);
    },
  })
  
  return (
    <Container>
      <Loading display={loading} />
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="studio-logo" width={225} height={150} />
          <FormikProvider 
          value={formik}
          >
          <Form style={{ margin: 15 }} 
           onSubmit={formik.handleSubmit}>
              <h1>{t('welcome')}</h1>
              <Input 
                name="username" 
                icon={FiUser}
                type="email" 
                placeholder="E-mail" 
                value={formik.values.username}
                onChange={formik.handleChange}
              />
              <Input 
                name="password" 
                icon={FiLock} 
                type="password" 
                placeholder={t('password')} 
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              <Button type="submit" $bgcolor="#373435" $color="#ffffff">
                <FiLogIn />
                {t('sign.in')}
              </Button>
              <Link className="stylelink" to="../register">{t('register.account')}</Link>
              <Link className="stylelink" to="../forgot">{t('forgot.password')}</Link>
            </Form>
          </FormikProvider>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default Login;