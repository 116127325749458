interface RoutesProps {
    name: string;
    component: string;
    path: string;
  }
  
  const Routes: RoutesProps[] = [
    { name: 'home', component: 'Home', path: '/' },
    { name: 'schedule', component: 'Schedule', path: 'schedule' },
    { name: 'schedule-create', component: 'ScheduleCreate', path: 'schedule/create' },
    { name: 'nomatch', component: 'NoMatch', path: '*' },
  ];
  
  export default Routes;