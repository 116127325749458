import React, { ReactElement } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import Template from '../components/Template';
import NoMatch from '../pages/NoMatch';
import routes from '../utils/routes';

const AppRoutes: React.FC = (): ReactElement => (
  <BrowserRouter future={{ v7_relativeSplatPath: true, v7_startTransition: true }}>
    <Template />
    <Routes>
      {routes.map((item) => {
        try {
          const ComponentItem = require(`../pages/${item.component}`).default;
          return <Route key={item.name} path={`${item.path}`} Component={(props: any) => <ComponentItem itemProps={item} {...props} />} />;
        } catch (err) {
          return <Route key={item.name} path={`${item.path}`} Component={(props: any) => <NoMatch {...props} />} />;
        }
      })}
      <Route Component={(props: any) => <NoMatch {...props} />} />
    </Routes>
  </BrowserRouter>
);

export default AppRoutes;