import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content:center
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 700px;
`;

const apearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Title = styled.h1`
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    font-family: sansSerif; 
    font-size: 30px;
    color: ${(props) => props.color || '#a68c5b;'};
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin-top: 2.5rem;
    font-family: sansSerif; 
    font-size: 40px;
    color: #a68c5b;
    margin-left: 1.7rem;
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    margin-top: 3.5rem;
    font-family: sansSerif; 
    font-size: 50px;
    color: #a68c5b;
    margin-left: 1.7rem;
  } 
  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    margin-top: 3.5rem;
    font-family: sansSerif; 
    font-size: 60px;
    color: #a68c5b;
    margin-left: 1.7rem;
  } 
  @media only screen and (min-width: 1201px) {
    margin-top: 4rem;
    font-family: sansSerif; 
    font-size: 70px;
    color: #a68c5b;
  } 
`

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  animation: ${apearFromLeft} 1s;
  
  @media only screen and (min-width: 320px) and (max-width: 480px) {
      .cardStyle {
        margin-top: 15px;
        padding: 0px;
        width: 300px;
        height: 150px;
        text-align: center;
        background-color: #373435;
        cursor: pointer;
      }
    }
    @media only screen and (min-width: 481px) and (max-width: 768px) {
      width: 30rem;
       .cardStyle {
        padding: 5px;
        width: 450px;
        height: 200px;
        text-align: center;
        margin-top: 5rem; 
        background-color: #373435;
        cursor: pointer;
      }
    }
    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      .cardStyle {
        padding: 5px;
        width: 600px;
        height: 250px;
        text-align: center;
        margin-top: 5rem; 
        background-color: #373435;
        cursor: pointer;
      } 
    }
    @media only screen and (min-width: 1025px) and (max-width: 1200px) {
      .cardStyle {
        padding: 5px;
        width: 700px;
        height: 300px;
        text-align: center;
        margin-top: 5rem; 
        background-color: #373435;
        cursor: pointer;
      }
    }
    @media only screen and (min-width: 1201px) {
      .cardStyle {
        width: 700px;
        height: 300px;
        text-align: center; 
        background-color: #373435;
        cursor: pointer;
      }  
      .stylerow {
        margin-top: 2em;
      }
    }
`;