import React, { useCallback, useEffect, useState } from 'react';

import { FiSave, FiSkipBack } from 'react-icons/fi';
import { Col, Divider, Input, Row, Select, Space, Table } from 'antd';
import type { SelectProps, TableColumnsType } from 'antd';
import { DatePicker, DatePickerProps } from 'antd';
import { Dayjs } from 'dayjs';
import moment from 'moment';

import {
  Container,
  Content,
  AnimationContainer,
  SpanLetter
} from './styles';


import { useNavigate } from 'react-router-dom';

import api from '../../services/api';
import { UseToast } from '../../context/Toast';
import { UseLanguage } from '../../context/Lang';
import { UseAuth } from '../../context/Auth';

import Loading from '../../components/Loading';
import Button from '../../components/Button';

import data from '../../utils/dataType'

interface DataType {
  key: React.Key;
  hour: string;
}

const ScheduleCreate: React.FC = () => {
  const { addToast } = UseToast();
  const [loading, setLoading] = useState(false);
  const { t } = UseLanguage();
  const navigate = useNavigate();
  const { signOut } = UseAuth();
  const options: SelectProps['options'] = [];
  const [optionServices, setOptionServices] = useState<any[]>();
  const [serviceChooiced, setServiceChooiced] = useState('');
  const [timeStart, setTimeStart] = useState('');
  const [timeFinish, setTimeFinish] = useState('');
  const [dateChoiced, setDateChoiced] = useState<String | String[]>([]);
  const [hourChoiced, setHourChoiced] = useState('');
  const [phone, setPhone] = useState('');
  const userSession = JSON.parse(localStorage.getItem('@theagenda:user')!);

  const fetchData = useCallback(async () => {
    const data = await api.get('v1/type-service/list-all');

    data.data.map((item: any) => {
      options.push({
        value: String(item.nameservice),
        label: item.nameservice,
        price: item.valueservice,
      })
      return options
    })
    setOptionServices(options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData().catch(console.error)
  }, [fetchData])

  const fetchDates = useCallback(async (date: any, service: string, hour: string) => {
    try {
      const datesAvailable = await api.post('v1/schedule/list-by-date-service', {
        datescheduled: date,
        typeservice: service,
        hour: hour
      })
      // eslint-disable-next-line
      datesAvailable.data.map((item: any) => {
        setTimeStart(item.hourstart)
        setTimeFinish(item.hourfinish)
      })
      // eslint-disable-next-line
      if (datesAvailable.data != '') {
        addToast({
          type: 'error',
          title: 'Desculpe, Horário Preenchido',
          description: 'Por Favor Escolha Outro Horário',
        })
      }
    } catch (e: any) {
      console.log('ops..', e)
    }
    // eslint-disable-next-line
  }, [])

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: 'hour',
      // eslint-disable-next-line
      render: (text: string) => <a>{text}</a>
    }
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRow: DataType[]) => {
      const { hour } = selectedRow[0]
      setHourChoiced(hour)
      fetchDates(dateChoiced, serviceChooiced, hour)
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.hour >= timeStart && record.hour <= timeFinish,
      hour: record.hour,
    }),
  };

  const onChange: DatePickerProps<Dayjs[]>['onChange'] = (date, dateString) => {
    setDateChoiced(dateString)
  }
  const disabledDate = (currentDate: Dayjs) => {
    return currentDate.isBefore(moment().format("YYYY-MM-DD"), 'day');
  };

  const onSave = async () => {
    try {

      const timeservice = await api.post('v1/type-service/list-one-service', {
        nameservice: serviceChooiced
      })
      if (timeservice.status === 401) {
        addToast({
          type: 'error',
          title: 'Token expirado.',
          description: 'Por favor faça o login novamente.',
        });
        signOut();
        navigate("/");
      }
      setLoading(true);
      await api.post('v1/schedule/create', {
        nameclient: userSession.firstname,
        datescheduled: dateChoiced,
        typeservice: serviceChooiced,
        hourstart: hourChoiced,
        hourfinish: String(Number(hourChoiced.slice(0, 2)) + Number(timeservice.data[0].timedurationservice) + ":00"),
        phonenumber: phone,
        username: userSession.username
      }).catch(error => {
          if (error.status === 401) {
            addToast({
              type: 'error',
              title: 'Token expirado.',
              description: 'Por favor faça o login novamente.',
            });
            signOut();
            navigate("/");
          }
        })
      addToast({
        type: 'success',
        title: 'Success',
        description: t('schedule.succes'),
      });
      setLoading(false);
      navigate('/');
    } catch (e: any) {
      addToast({
        type: 'error',
        title: 'Error',
        description: t('something.is.wrong'),
      });
      setLoading(false);
      navigate('/');
      console.log('ops..', e)
    }
  }

  return (
    <Container>
      <Loading display={loading} />
      <Content>
        <AnimationContainer>
          <h1>{t('scheduling')}</h1>
          <Divider />
          <Space.Compact>
            <Col>
              <Row className='rowStyle'>
                <Col sm={24} md={24} lg={24}>
                  <SpanLetter>{t("choose.service")}</SpanLetter>
                </Col>
              </Row>
              <Row className='rowStyle'>
                <Col sm={24} md={24} lg={24}>
                  <Select
                    id='service'
                    showSearch={false}
                    className='sizeInput'
                    options={optionServices}
                    value={serviceChooiced}
                    onChange={(e) => setServiceChooiced(e.valueOf())}
                  />
                </Col>
              </Row>
              <Row className='rowStyle'>
                <Col sm={24} md={24} lg={24}>
                  <SpanLetter>{t('choose.date')}</SpanLetter>
                </Col>
              </Row>
              <Row className='rowStyle'>
                <Col sm={24} md={24} lg={24}>
                  <DatePicker
                    id='date'
                    name='date'
                    className='sizeInput'
                    onChange={onChange}
                    disabledDate={disabledDate}
                    onFocus={e => e.target.blur()}
                  />
                </Col>
              </Row>
              <Row className='rowStyle'>
                <Col sm={24} md={24} lg={24}>
                  <SpanLetter>{t('phone')}</SpanLetter>
                </Col>
              </Row>
              <Row className='rowStyle'>
                <Col sm={24} md={24} lg={24}>
                  <Input
                    className='sizeInput'
                    name='phone'
                    type='tel'
                    maxLength={11}
                    value={phone}
                    onChange={e => setPhone(e.currentTarget.value)}
                    placeholder='Cel'
                  />
                </Col>
              </Row>
              <Row className='rowStyle'>
                <Col sm={24} md={24} lg={24}>
                  <SpanLetter>{t('choose.time')}</SpanLetter>
                </Col>
              </Row>
              <Row className='paddingBottom'>
                <Col sm={24} md={24} lg={24}>
                  <Table
                    className='textwidth'
                    rowSelection={{
                      type: 'radio',
                      ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                  />
                </Col>
              </Row>
            </Col>
          </Space.Compact>
        </AnimationContainer>
        <Row className='positionRow'>
          <Button className='buttonStyle' $bgcolor="#373435" $color="#ffffff" onClick={() => navigate('/')}>
            <FiSkipBack className='ficolor' />
            <span>{t('back')}</span>
          </Button>
          <Button className='buttonStyle' type='button' $bgcolor="#373435" $color="#ffffff" onClick={onSave}>
            <FiSave className='ficolor' />
            <span>{t('confirm')}</span>
          </Button>
        </Row>
      </Content>
    </Container>
  );
};

export default ScheduleCreate;
