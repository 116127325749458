import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content:center;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .positionbutton {
      position: absolute;
      bottom: 20px;
      width: 150px;
    } 
    .ficolor {
      color: #a68c5b;
    }
  }
`;
export const Span = styled.span`
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    color: #a68c5b;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 700px;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .subheader {
      color: #a68c5b;
      margin: 5px 0px -15px;  
    }
  }
`;

const apearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const CardSchedulesH1 = styled.h1`
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    font-family: sansSerif; 
    font-size: 18px;
    color: #a68c5b;
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    font-family: sansSerif; 
    font-size: 25px;
    color: #a68c5b;
    margin-left: 1.7rem;
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    font-family: sansSerif; 
    font-size: 40px;
    color: #a68c5b;
  } 
  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    margin-top: 3.5rem;
    font-family: sansSerif; 
    font-size: 60px;
    color: #a68c5b;
    margin-left: 1.7rem;
  } 
  @media only screen and (min-width: 1201px) {  
    margin-top: 6rem;
    font-family: sansSerif; 
    font-size: 70px;
    color: #a68c5b;
    margin-left: 1.7rem;
  }
`;
export const CardNoSchedulesH1 = styled.h1`
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    margin-top: -1em;
      font-family: sansSerif; 
      font-size: 18px;
      color: #a68c5b;
  }
`;
export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  max-height: 70vh;


  animation: ${apearFromLeft} 1s;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .cardStyle {
      width: 250px;
      height: 135px;
      text-align: center;
      background-color: #373435;
      cursor: pointer;
      justify-items: center;
    }
    .noschedules {
      width: 250px;
      height: 135px;
      text-align: center;
      background-color: #373435;
      cursor: pointer;
      justify-items: center;
    } 
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    .cardStyle {
      padding: 5px;
      width: 450px;
      height: 200px;
      text-align: center;
      margin-top: 5rem; 
      background-color: #373435;
      cursor: pointer;
      justify-items: center;
    }
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    .cardStyle {
      padding: 5px;
      width: 600px;
      height: 250px;
      text-align: center;
      margin-top: 5rem; 
      background-color: #373435;
      cursor: pointer;
      justify-items: center;
    }
  }
  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .cardStyle {
      padding: 5px;
      width: 700px;
      height: 300px;
      text-align: center;
      margin-top: 5rem; 
      background-color: #373435;
      cursor: pointer;
      justify-items: center;
    } 
  }
  @media only screen and (min-width: 1201px) {
    .cardStyle {
      padding: 5px;
      width: 800px;
      height: 400px;
      text-align: center;
      margin-top: 5rem; 
      background-color: #373435;
      cursor: pointer;
      justify-items: center;
    }
  }
`;